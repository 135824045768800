import repository from './repository'

const resource = 'stock-feed-details'

export default {
  index () {
    return repository.get(resource)
  },
  priceList (params) {
    return repository.get(`${resource}/price-list`, { params })
  },
  store (data) {
    return repository.post(resource, data)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
  massUpdate (data) {
    return repository.post(`${resource}/mass-update`, { data })
  },
}
