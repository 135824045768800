import ExcelJs from 'exceljs'

let workbook
let tableData = []

function rowToObject (worksheet, columnConfig) {
  worksheet.eachRow((row, rowIndex) => {
    if (rowIndex > 1) {
      const tableRow = {}
      row.eachCell((cell, colIndex) => {
        tableRow[columnConfig[colIndex - 1].header] = cell.value
      })
      tableData.push(tableRow)
    }
  })
}

function setColumnKeyAndHeader (headerRow) {
  return function (col, index) {
    col.header = headerRow.getCell(index + 1).value
    col.key = headerRow.getCell(index + 1).value
  }
}

function bufferCallback (_callback) {
  return function () {
    workbook.eachSheet(worksheet => {
      // debugger
      const headerRow = worksheet.getRow(1)
      const columnConfig = worksheet.columns
      columnConfig.forEach(setColumnKeyAndHeader(headerRow))
      rowToObject(worksheet, columnConfig)
    })
    _callback(tableData)
  }
}

async function bufferExcel (file, _callback) {
  await workbook.xlsx.load(file)
  _callback()
}
export default {
  async loadExcel (file, _callback) {
    tableData = []
    workbook = new ExcelJs.Workbook()
    bufferExcel(file, bufferCallback(_callback))
  },
}
