<template lang="pug">
  v-container
    base-snackbar(

    )
    v-toolbar(
      color="primary"
      dark
      elevation="0"
      dense
    )
      span Feed Details
      v-spacer
      //- v-btn(
      //-   text
      //-   color="white"
      //-   small
      //-   @click=""
      //- )
      //-   v-icon mdi-file
      //-   span Update Price
      v-btn(
        text
        color="white"
        small
        @click="printPriceList()"
        :loading="priceListGetting"
      )
        v-icon mdi-file
    v-data-table(
      :items="stockFdDtlList"
      :headers="headers"
      :loading="stockFdDtlGetting"
      dense
      single-expand
    )
      template(v-slot:top)
        v-row
          v-col(cols="12")
            v-file-input(
              accept=".xlsx,xls"
              label="Update Prices"
              v-model="uploadFile"
              hide-details
            )
              template(v-slot:append-outer)
                v-btn(
                  text
                  small
                  @click="upload"
                  :loading="uploadingFile"
                  color="green"
                )
                  v-icon mdi-cloud-upload
      template(v-slot:item="{ item, isExpanded, expand }")
        tr
          td.border-right
            v-btn.pa-0.ma-0(
              text
              x-small
              color="yellow"
              @click="editItem(item)"
            )
              v-icon mdi-pencil
            v-btn.pa-0.ma-0(
              text
              x-small
              color="red"
              :disabled="!!item.customer_forecast_types_count"
              @click="deleteDetail(item)"
            )
              v-icon mdi-delete
            span {{ item.stock.description || null }}
          td.border-right(align="right")
            span {{ item.size }} kg
          td(align="right")
            v-btn.pa-0.ma-0(
              text
              x-small
              color="blue-grey"
              :class="isExpanded ? 'is-expanded' : ''"
              @click="expand(!isExpanded)"
            )
              v-icon mdi-chevron-down
      template(v-slot:expanded-item="{ item }")
        td(colspan="3")
          v-row.border-bottom.ma-0
            v-col.py-0(cols="3" align="center")
              span.grey--text Cost / Bag
            v-col.py-0(cols="3" align="center")
              span.grey--text Cost / Kilo
            v-col.py-0(cols="3" align="center")
              span.grey--text Resell / Bag
            v-col.py-0(cols="3" align="center")
              span.grey--text Resell / Kilo
          v-row.ma-0
            v-col.py-0.border-right(cols="3" align="right")
              span {{ $float(item.per_bag).toFixed(2) }}
            v-col.py-0.border-right(cols="3" align="right")
              span {{ $float(item.per_kilo).toFixed(2) }}
            v-col.py-0.border-right(cols="3" align="right")
              span {{ $float(item.resell_per_bag).toFixed(2) }}
            v-col.py-0(cols="3" align="right")
              span {{ $float(item.resell_per_kilo).toFixed(2) }}
</template>
<style lang="scss" scoped>
  .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    box-shadow: none;
  }
  .border-bottom {
    border-bottom: 1px solid lightgray;
  }
  .border-top {
    border-top: 1px solid lightgray;
  }
  .border-left {
    border-left: 1px solid lightgray;
  }
  .border-right {
    border-right: 1px solid lightgray;
  }
  .v-btn.is-expanded .v-icon {
    transform: rotate(-180deg);
  }
</style>
<script>
import storeStockFdDtl from '@/repositories/stock-feed-detail.repository'
import HericaPriceListPdf from '@/pdfs/herica-price-list.pdf'
import { getVars, errorHandler, requestVars } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'
import readExcel from '@/libs/read-excel'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'

const [priceListGetVars, priceListGetVarNames] = requestVars({ indentifier: 'price-list', hasData: false })

const getPriceListHandler = new VueRequestHandler(null, priceListGetVarNames)
const getStockFdDtlDelay = searchDelay()

const tableVars = () => ({
  headers: [
    { text: 'Item', value: 'stock_id' },
    { text: 'Size Per Bag', value: 'size' },
    { text: '', value: 'data-table-expanded', width: 50 },
    // { text: 'Per Bag', value: 'per_bag' },
    // { text: 'Per Kilo', value: 'per_kilo' },
  ],
  uploadingFile: false,
  uploadFile: null,
})

export default {
  name: 'StockFeedDetail',
  created() {
    this.getStockFdDtl()
    this.websocketEvents()
  },
  data() {
    return {
      ...tableVars(),
      ...getVars('stock-fd-dtl'),
      ...priceListGetVars,
    }
  },
  methods: {
    upload() {
      if (!this.uploadFile) return
      if (this.uploadingFile) return
      this.uploadingFile = true
      readExcel.loadExcel(this.uploadFile, result => {
        storeStockFdDtl.massUpdate(result)
          .then(() => { alert('update success') })
          .catch(e => { alert(e) })
          .then(() => { this.uploadingFile = false })
      })
    },
    getStockFdDtl() {
      if (this.stockFdDtlGetting) return
      this.stockFdDtlGetting = true
      this.stockFdDtlGetErrors = []
      getStockFdDtlDelay(() => {
        storeStockFdDtl.index()
          .then(({ data }) => { this.stockFdDtlList = data })
          .catch(e => { this.stockFdDtlGetErrors = errorHandler(e) })
          .then(() => { this.stockFdDtlGetting = false })
      })
    },
    deleteDetail(item) {
      const { stock } = item
      // if (stock.customer_forecast_types_count) return
      // const e = 0
    },
    editItem(item) {
      if (!item.stock_id) return
      this.$emit('edit-item', item)
      this.$router.push({ name: 'stocks.feed-details.edit', params: { stock: item.stock_id } })
    },
    websocketEvents() {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.dbStoreEvent)
        .listen('DBUpdateEvent', this.dbUpdateEvent)
        .listen('DBMassUpdateEvent', this.dbMassUpdateEvent)
    },
    dbStoreEvent({ model, data }) {
      if ([!model, this.$objectEmpty(data)].or()) return
      if (model === 'StockFeedDetail') {
        const stockFeedDetails = this.stockFdDtlList.map(e => ({ ...e }))
        this.stockFdDtlList = stockFeedDetails.objectInsertion(data, 'stock_id', 'stock_id')
      }
    },
    dbUpdateEvent({ model, data }) {
      if ([!model, this.$objectEmpty(data)].or()) return
      if (model === 'StockFeedDetail') {
        this.stockFeedDetailUpdated(data)
      }
    },
    dbMassUpdateEvent({ model, data }) {
      if (!model || !data.length) return
      if (model === 'StockFeedDetail') {
        data.forEach(item => {
          this.stockFeedDetailUpdated(item)
        })
      }
    },
    printPriceList() {
      const pdf = new HericaPriceListPdf()
      const handler = getPriceListHandler
      const repository = storeStockFdDtl.priceList
      const params = {
        sc: 'BRF05,CBF06,CLF02,DYF03,GCF04,SSF07,STHF01,BPF01',
      }
      handler.setVue(this)
      handler.execute(repository, [params], ({ data }) => {
        const pdfData = data.map(item => {
          const description = item.description || null
          const size = `${item.size} kg`
          const perBag = item.resell_per_bag ? item.resell_per_bag.currencyFormat() : null
          const perKilo = item.resell_per_kilo ? item.resell_per_kilo.currencyFormat() : null
          return [description, size, perBag, perKilo]
        })
        
        pdf.generate(pdfData)
      })
    },
    stockFeedDetailUpdated(data) {
      const stockFeedDetails = this.stockFdDtlList.map(e => ({ ...e }))
      const index = stockFeedDetails.findIndex(item => item.id === data.id)
      if (!index && typeof index !== 'number') return
      Object.assign(stockFeedDetails[index], data)
      this.stockFdDtlList = stockFeedDetails
    },
  },
}
</script>
