import Pdf from './pdf'
import 'jspdf-autotable'

const duckLogo = require('@/data-uri/duck-logo.datauri').default

export default class HericaPriceListPdf extends Pdf {
  constructor () {
    super()
    this.addFonts()
  }

  header () {
    const [x, y] = [10, 15]
    this.doc.addImage(duckLogo, 'PNG', x, y - 8, 18.6, 21.5)
    this.generateText({ text: 'Herica Animal Feeds Trading', fontSize: 18, coordinates: [x + 22, y] })
    this.generateText({ text: 'OFFICIAL PRICE LIST', fontSize: 12, coordinates: [x + 22, y + 5] })
    this.generateText({ text: 'Effective May 1, 2022', coordinates: [x + 22, y + 10] })
    this.displayContact(275)
  }

  table (data) {
    this.doc.autoTable({
      styles: {
        lineWidth: 0.2,
        lineColor: [201, 201, 201],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      theme: 'grid',
      head: [['Description', 'Size', 'Per Bag', 'Per Kilo']],
      body: data,
      startY: 30,
      columnStyles: {
        2: { halign: 'center' },
        3: { halign: 'center' },
      },
    })
    const { finalY } = this.doc.lastAutoTable
    this.displayContact(finalY)
  }

  displayContact (y) {
    this.generateText({ text: 'Godofredo Salibongcogon Jr.', coordinates: [15, y + 10] })
    this.generateText({ text: 'Sales and Marketing', coordinates: [15, y + 15] })
    this.generateText({ text: '0917 677 7273', coordinates: [15, y + 20] })
  }

  save () {
    this.doc.output('dataurlnewwindow')
  }

  generate (data) {
    this.header()
    this.table(data)
    this.save()
  }
}