import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import { calibriBold, calibriRegular } from '@/fonts/calibri.font'

export default class PDF {
  constructor () {
    this.doc = new jsPDF()
  }

  addFonts () {
    this.doc.addFileToVFS(calibriBold.postScriptName, calibriBold.encoding)
    this.doc.addFileToVFS(calibriRegular.postScriptName, calibriRegular.encoding)
    this.doc.addFont('Calibri Bold.TTF', 'calibri', 'bold')
    this.doc.addFont('Calibri Regular.ttf', 'calibri', 'normal')
  }

  subtractToLength (length, text, size) {
    return length - this.getTextWidth(text, size)
  }

  getTextWidth (text = null, size = 12) {
    const MM_TO_POINT_RATIO = 2.83465
    if (!text) return 0
    return (this.doc.getStringUnitWidth(text) * size) / MM_TO_POINT_RATIO
  }

  generateText (args = {}) {
    const {
      font = 'calibri',
      fontStyle = 'normal',
      fontSize = 11,
      color = [0, 0, 0],
      text = '',
      coordinates = [0, 0],
    } = args
    this.doc.setFont(font, fontStyle)
    this.doc.setFontSize(fontSize)
    this.doc.setTextColor(...color)
    this.doc.text(text, ...coordinates)
    return this.getTextWidth(text, fontSize)
  }

  generateField (attributes) {
    const {
      label = '',
      labelFont = 'calibri',
      labelSize = 12,
      labelTextColor = [0, 0, 0],
      coordinates = [10, 10],
      lineLength = 71,
      textLength = null,
      value = '',
      valueFont = 'calibri',
      valueSize = 11,
      valueTextColor = [0, 0, 0],
      delimiter = null,
    } = attributes

    const [x, y] = coordinates
    const labelAttributes = [label].concat(coordinates)
    this.doc.setFont(labelFont)
    this.doc.setFontSize(labelSize)
    this.doc.setTextColor(...labelTextColor)
    this.doc.text(...labelAttributes)

    const textWidth = !textLength ? this.getTextWidth(label, labelSize) : textLength
    const labelEnd = x + textWidth
    const delimiterLength = this.getTextWidth(delimiter)

    this.doc.setFont(valueFont)
    this.doc.setFontSize(valueSize)
    this.doc.setTextColor(...valueTextColor)
    this.doc.text(value, labelEnd + delimiterLength + 2, y)
    if (delimiter) this.doc.text(delimiter, labelEnd, y)
    this.doc.line(labelEnd + delimiterLength, y + 1, labelEnd + delimiterLength + lineLength, y + 1)
    return textWidth
  }
}